/* src/components/Navbar.css */
.navbar {
  background-color: #312f30; /* Warna latar belakang navbar */
}

.navbar-brand {
  color: #ecf0f1 !important; /* Warna teks untuk brand */
  font-weight: bold; /* Menguatkan teks judul */
}

.navbar-link {
  color: #ecf0f1 !important; /* Warna teks link */
  display: flex;
  align-items: center; /* Pusatkan ikon dan teks secara vertikal */
  margin-left: 0px; /* Spasi antar link */
  transition: color 0.3s; /* Efek transisi pada warna */
}

.navbar-link .icon {
  margin-right: 5px; /* Spasi antara ikon dan teks */
}

.navbar-link:hover {
  color: #dfb455 !important; /* Ubah warna saat hover */
}

.navbar-link {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
  cursor: pointer; /* Pointer cursor for interactivity */
}

.hamburger-icon {
  font-size: 20px; /* Increase size as needed */
  cursor: pointer; /* Pointer cursor for interactivity */
}
