/* src/components/Sidebar.css */
.sidebar {
  width: 250px; /* Adjust width as needed */
  height: 100vh; /* Full height of the viewport */
  background-color: #f8f9fa; /* Background color */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visual separation */
  padding: 0px;
}

.sidebar-header {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center; /* Pusatkan teks */
}

.sidebar-menu {
  list-style: none;
  padding: 0px 10px; /* Hapus padding default */
}

.sidebar-menu-container {
  height: calc(100vh - 180px);
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: 'white';
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.scroll-hidden::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* Hide scrollbar for Firefox */
.scroll-hidden {
  overflow: -moz-scrollbars-none; /* Hides the scrollbar */
  scrollbar-width: none; /* Hides the scrollbar */
}

/* General overflow */
.scroll-hidden {
  overflow-y: scroll; /* Enables vertical scrolling */
}


.sidebar-menu li {
  margin: 5px 0; /* Spasi antar item */
}

.sidebar-link {
  display: flex;
  align-items: center; /* Pusatkan ikon dan teks secara vertikal */
  padding: 10px 15px; /* Spasi dalam link */
  color: #ecf0f1; /* Warna teks */
  text-decoration: none; /* Hapus garis bawah */
  transition: background-color 0.3s; /* Efek transisi */
  border-radius: 5px; /* Sudut membulat */
}

.sidebar-link:hover {
  background-color: #dfb455 /* Warna latar belakang saat hover */
}

.icon {
  margin-right: 10px; /* Spasi antara ikon dan teks */
}

.active {
  background-color: #dfb455; /* Warna latar belakang untuk link aktif */
  color: #312f30;
}
