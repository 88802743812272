.dashboard-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  
  .table-container {
    max-height: 580px;
    overflow-y: auto;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .scroll-hidden::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .data-table thead {
    background-color: #dfb455;
    color: #312f30;
    position: sticky;
    top: 0; /* Header akan tetap di posisi 0 dari atas saat di-scroll */
    z-index: 10; /* Pastikan header berada di depan konten lainnya */
  }
  
  .data-table th, .data-table td {
    padding: 5px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .data-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  .scroll-hidden::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
  }

  @media screen and (max-width: 768px) {
    .data-table thead {
      display: none;
    }
  
    .data-table, .data-table tbody, .data-table tr, .data-table td {
      display: block;
      width: 100%;
    }
  
    .data-table tr {
      margin-bottom: 15px;
    }
  
    .data-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .data-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  