/* RegisterAdmin.css */

.container {
  display: flex;
    justify-content: center;
    align-items: center;


  }
  
  .register-box {
    background-color: #fff; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Shadow for elevation */
    padding: 30px; /* Spacing inside the box */
    width: 100%;
    max-width: 400px; /* Limit width */
  }
  
  .register-box h2 {
    color: #312f30; /* Secondary color */
    margin-bottom: 20px;
  }
  
  .form-control {
    border: 1px solid #dfb455; /* Primary color for input borders */
    border-radius: 4px; /* Slightly rounded corners */
  }
  
  .btn-primary {
    background-color: #dfb455; /* Primary button color */
    border-color: #dfb455; /* Match border with primary color */
  }
  
  .btn-primary:hover {
    background-color: #cfa849; /* Darken the primary color on hover */
    border-color: #cfa849; /* Match border on hover */
  }
  
  .modal-header {
    background-color: #312f30; /* Dark header for modal */
    color: white; /* White text for the header */
  }
  
  .modal-footer .btn-secondary {
    background-color: #dfb455; /* Match with primary color */
    border-color: #dfb455; /* Border color */
  }
  
  .modal-footer .btn-secondary:hover {
    background-color: #cfa849; /* Darken on hover */
  }
  