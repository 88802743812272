body {
    background-color: #312f30;
    color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #312f30;
  }
  
  .login-box {
    background-color: #f4f4f4;
    color: #312f30;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    color: #dfb455;
    margin-bottom: 20px;
  }
  
  .form-control {
    border-radius: 5px;
  }
  
  .btn-primary {
    background-color: #dfb455;
    border: none;
    color: #312f30;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #c9a84f;
  }
  